import {
  CompaniesApiFactory,
  useApiFactory,
  useQueryFetch,
  UsersApiFactory,
} from '@homeproved/shared/data-access';
import { useQueryClient } from 'react-query';

export const useCompany = (forceReFetch = false) => {
  const usersApi = useApiFactory(UsersApiFactory);
  // because id is passed as a prop and use to enable the query, we check if the data exists in cache, to avoid refetching on every render
  const cachedQueryData = useQueryClient().getQueryData(['company']);
  const {
    query: { data, isLoading, isSuccess, error, refetch },
  } = useQueryFetch(['company'], () => usersApi.apiAuthCompanyGet(), {
    options: {
      enabled: !cachedQueryData || forceReFetch,
      cacheTime: forceReFetch && 0,
    },
  });

  return { company: data?.data, isLoading, isSuccess, error, refetch };
};

export const useAnonymousCompany = (idOrSlug?: string, forceReFetch = false) => {
  const companiesApi = useApiFactory(CompaniesApiFactory);
  // because id is passed as a prop and use to enable the query, we check if the data exists in cache, to avoid refetching on every render
  const cachedQueryData = useQueryClient().getQueryData(['company', idOrSlug]);
  const {
    query: { data, isLoading, isSuccess, error, refetch },
  } = useQueryFetch(['company', idOrSlug], () => companiesApi.apiCompaniesCompanyGet(idOrSlug), {
    options: {
      enabled: idOrSlug != null && (!cachedQueryData || forceReFetch),
      cacheTime: forceReFetch && 0,
    },
  });

  return { company: data?.data, isLoading, isSuccess, error, refetch };
};

export const useTotalCompaniesCount = () => {
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query } = useQueryFetch('totalCompaniesCount', () =>
    companiesApi.apiCompaniesCounterGet()
  );
  return {
    data: query.data,
    isSuccess: query.isSuccess,
    isError: query.isError,
  };
};
