import React, { FC, FocusEvent, useState } from 'react';
import {
  ErrorMessage,
  FormGroup,
  GooglePlacesAddressAutoComplete,
  Input,
  processVatInput,
  Radio,
  useTavNumberBlur,
} from '@homeproved/shared/feature-forms';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GetCompanyByVatHandler } from './GetCompanyByVatHandler';
import { CompanyData } from '@homeproved/shared/data-access';
import { RadioGroup } from '@material-ui/core';

type CompanyFormProps = {
  onUpdateByCompany?: (company?: CompanyData) => void;
  optionalFields?: boolean;
  companyById?: CompanyData;
  prefillRegistrationFormCompleted?: boolean;
  onTavNumberChanged?: () => void;
};

export const CompanyForm: FC<CompanyFormProps> = ({
  onUpdateByCompany,
  optionalFields = false,
  companyById,
  prefillRegistrationFormCompleted = false,
  onTavNumberChanged,
}) => {
  const { t } = useTranslation();
  const { register, errors, watch, setValue } = useFormContext();
  const selectedCountry = watch('country');
  const isBelgium = selectedCountry === 'BE';
  const [fetchingCompanyByVat, setFetchingCompanyByVat] = useState(false);
  const [fetchCompanyByVatCompleted, setFetchCompanyByVatCompleted] = useState(false);
  const {
    handleTavNumberBlur,
    tavNumber,
    tavNumberIsValid,
    resetTavNumberBlur,
  } = useTavNumberBlur();

  const handleCompanyByTav = (company?: CompanyData) => {
    setFetchingCompanyByVat(false);
    if (company == null) {
      if (onUpdateByCompany != null) setTimeout(onUpdateByCompany, 0);
      return;
    }
    updateFormByCompany(company);
    setFetchCompanyByVatCompleted(true);
    resetTavNumberBlur();
  };

  const updateFormByCompany = (company: CompanyData) => {
    if (onUpdateByCompany != null) onUpdateByCompany(company);
  };

  return (
    <>
      {tavNumberIsValid && !fetchCompanyByVatCompleted && (
        <GetCompanyByVatHandler
          vat={processVatInput(tavNumber)}
          onFetching={() => setFetchingCompanyByVat(true)}
          onCompany={handleCompanyByTav}
        />
      )}
      {!companyById && (
        <FormGroup>
          <RadioGroup
            aria-label="country"
            name="country"
            ref={register}
            onChange={(e) => setValue('country', e.target.value)}
            defaultValue={'BE'}
          >
            <Radio value={'BE'} height={3} label={t('countries.be')} />
            <Radio value={'NL'} height={3} label={t('countries.nl')} />
          </RadioGroup>
          {errors.country && <ErrorMessage>{t(errors.country.message)}</ErrorMessage>}
        </FormGroup>
      )}
      {(!companyById || companyById.vat == null) && (
        <FormGroup>
          <Input
            placeholder={`${
              isBelgium ? t('shared.form.company.tav') : t('shared.form.company.kvk')
            }${optionalFields ? '' : ' *'}`}
            name="vat"
            ref={register}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              if (e.target.value !== tavNumber) setFetchCompanyByVatCompleted(false);
              if (onTavNumberChanged != null) onTavNumberChanged();
              handleTavNumberBlur(e, selectedCountry);
            }}
          />
          {errors.vat && (
            <ErrorMessage>
              {t(
                isBelgium
                  ? 'shared.form.validation.invalidBelgianVatNumber'
                  : 'shared.form.validation.invalidDutchVatNumber'
              )}
            </ErrorMessage>
          )}
        </FormGroup>
      )}
      {!companyById && (
        <FormGroup>
          <Input
            placeholder={`${t('shared.form.company.name')} *`}
            id="name"
            name="name"
            ref={register}
            readOnly={fetchingCompanyByVat}
          />
          {errors.name && <ErrorMessage>{t(errors.name.message)}</ErrorMessage>}
        </FormGroup>
      )}

      {!companyById && (
        <GooglePlacesAddressAutoComplete
          updateByVatCompleted={fetchCompanyByVatCompleted}
          companyById={companyById}
          prefillRegistrationFormCompleted={prefillRegistrationFormCompleted}
        />
      )}

      {isBelgium && (!companyById || companyById.email == null) && (
        <FormGroup>
          <Input
            placeholder={`${t('shared.form.user.email')}${optionalFields ? '' : ' *'}`}
            id="email"
            name="email"
            ref={register}
            readOnly={fetchingCompanyByVat}
          />
          {errors.email && <ErrorMessage>{t(errors.email.message)}</ErrorMessage>}
        </FormGroup>
      )}
      {isBelgium && !companyById && (
        <FormGroup>
          <Input
            placeholder={`${t('shared.form.user.phone')}`}
            name="phone"
            ref={register}
            readOnly={fetchingCompanyByVat}
          />
          {errors.phone && <ErrorMessage>{t(errors.phone.message)}</ErrorMessage>}
        </FormGroup>
      )}
      {isBelgium && !companyById && (
        <FormGroup>
          <Input
            placeholder={t('shared.form.user.mobile')}
            name="mobile"
            ref={register}
            readOnly={fetchingCompanyByVat}
          />
          {errors.mobile && <ErrorMessage>{t(errors.mobile.message)}</ErrorMessage>}
        </FormGroup>
      )}
      {!companyById && (
        <FormGroup>
          <Input
            placeholder={t('shared.form.company.site')}
            name="url"
            ref={register}
            readOnly={fetchingCompanyByVat}
          />
          {errors.url && <ErrorMessage>{t(errors.url.message)}</ErrorMessage>}
        </FormGroup>
      )}
    </>
  );
};
