import { CompaniesApiFactory, useApiFactory, useQueryFetch } from '@homeproved/shared/data-access';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

export const useCompaniesLatestGet = (sectorSlug, location) => {
  const currentLanguage = useCurrentLanguage();
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query } = useQueryFetch(['companies', sectorSlug, location, currentLanguage], () => {
    if (sectorSlug == null) {
      return companiesApi.apiCompaniesLatestGet();
    }
    if (location == null) {
      return companiesApi.apiSectorSectorCompaniesGet(sectorSlug, 'desc', 20);
    }
    return companiesApi.apiSectorSectorCompaniesGet(
      sectorSlug,
      'desc',
      20,
      location.lat,
      location.lng
    );
  });

  return {
    data: query.data,
    isSuccess: query.isSuccess,
    isError: query.isError,
  };
};

export const useCompaniesWithScoreGet = (page , perPage, options?) => {
  const currentLanguage = useCurrentLanguage();
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query } = useQueryFetch(['companies', currentLanguage], () => {
    return companiesApi.apiCompaniesWithScoreGet(page , perPage, options);
  });

  return {
    query: query,
    data: query.data,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
  };
};
